import { UserProfileDto } from '@app/api/models';
import { UserProfileDto as UserProfileDtoProxy } from '@shared/service-proxies/service-proxies';


export function isVirtualUserProfile(up: UserProfileDto): boolean {
  return !up?.id && !!up?.externalIds && Object.keys(up.externalIds).length > 0;
}

export function isVirtualUserProfileProxy(up: UserProfileDtoProxy): boolean {
  return !up?.id && !!up?.externalIds && Object.keys(up.externalIds).length > 0;
}