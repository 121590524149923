<div class="container">
    <form class="form-container"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="onSubmit()">
        <ng-container *ngFor="let field of _fields;"
            dynamicField
            [readonly]="readonly"
            [field]="field"
            [group]="form"
            [userProfiles]="userProfiles"
            [lazyAutocomplete]="lazyAutocomplete">
        </ng-container>
    </form>
</div>