import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfileDto } from '@app/api/models';
import { FildelityCardFormModel } from './fidelity-card-form-model.interface';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotEmptyFFFieldsValidator } from '@app/shared/validators/not-empty-ff-fields-validator';
import { AppConsts } from '@shared/AppConsts';
import { SelectItem } from 'primeng';
import { KeyValuePair } from '@app/shared/models/ClientFlightModels';
import { CheckoutService } from '@app/shared/services/flight/checkout.service';
import { Observable, map, of, startWith } from 'rxjs';
import { CardProgramIdValidator } from '@app/shared/validators/card-program-id-validator';
import { FildelityCardDialogInputModel } from './fidelity-card-dialog-input-model.interface';

@Component({
	selector: 'app-fidelity-card-dialog',
	templateUrl: './fidelity-card-dialog.component.html',
	styleUrls: ['./fidelity-card-dialog.component.scss'],
})
export class FidelityCardDialogComponent {
	form: FormArray<FormGroup<FildelityCardFormModel>>;

	private userProfile: UserProfileDto;
	readonly: boolean;

	private fidelityItemList: SelectItem[];
	filteredFidelityItemList: Observable<SelectItem[]>[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: FildelityCardDialogInputModel,
		private dialogRef: MatDialogRef<FidelityCardDialogComponent>,
		private fb: FormBuilder,
		private checkoutService: CheckoutService
	) {
		this.userProfile = data.userProfile;
		this.readonly = data.readonly ?? false;
	}

	ngOnInit(): void {
		this.initDialog();
		this.initSelectList();
		this.initForm();
	}

	initDialog() {
		/*this.dialogRef.disableClose = true;
        this.dialogRef.backdropClick().subscribe(() => {
            this.closeDialog();
        });*/
	}

	initForm() {
		this.form = this.fb.array<FormGroup<FildelityCardFormModel>>(
			this.userProfile.fidelityCardPrograms?.map((fidelityCardProgram) =>
				this.fb.group<FildelityCardFormModel>({
					programId: new FormControl({ value: fidelityCardProgram.programId, disabled: this.readonly }, [
						NotEmptyFFFieldsValidator('membershipId'),
						CardProgramIdValidator(this.fidelityItemList.map((x) => x.value)),
						Validators.required,
					]),
					membershipId: new FormControl({ value: fidelityCardProgram.membershipId, disabled: this.readonly }, [
						NotEmptyFFFieldsValidator('programId'),
						Validators.required,
					]),
				})
			) ?? []
		);

		/* Duplicate Codes For Each Form Control */
		this.filteredFidelityItemList =
			this.userProfile?.fidelityCardPrograms?.map((_, idx) => {
				return this.newFilteredSelectItemsObs(idx);
			}) ?? [];
	}

	closeDialog(save: boolean = false) {
		if (save && !this.readonly) {
			if (this.form.valid) {
				this.dialogRef.close(this.form);
			} else {
				this.form.markAllAsTouched();
			}

			return;
		}

		this.dialogRef.close();
	}

	newCard() {
		if (!this.readonly) {
			const fidelityCardProgram = this.fb.group<FildelityCardFormModel>({
				programId: new FormControl({ value: '', disabled: this.readonly }, [
					NotEmptyFFFieldsValidator('membershipId'),
					CardProgramIdValidator(this.fidelityItemList.map((x) => x.value)),
					Validators.required,
				]),
				membershipId: new FormControl({ value: '', disabled: this.readonly }, [NotEmptyFFFieldsValidator('programId'), Validators.required]),
			});

			this.form.push(fidelityCardProgram);
			this.filteredFidelityItemList.push(this.newFilteredSelectItemsObs(this.form.length - 1));
		}
	}

	removeCard(index: number) {
		if (!this.readonly) {
			this.form.removeAt(index);
			this.filteredFidelityItemList.splice(index, 1);
		}
	}

	getLogoFidelityCard(type: string, filename: string) {
		if (filename && type) {
			const filenameLower = filename ? filename.toUpperCase() : filename;
			try {
				if (type === 'airlines') {
					return AppConsts.cdnBaseUrl + `/images/${type}/` + filenameLower + '_SM.png';
				} else if (type === 'train') {
					return AppConsts.cdnBaseUrl + `/images/trains/svg/trenitalia.svg`;
				} else {
					return '';
				}
			} catch (e) {
				return '';
			}
		} else {
			return '';
		}
	}

	displayFunction = (_key?: string): string | undefined => {
		const key = _key?.toLowerCase()?.trim() ?? '';
		const label = !!key ? this.fidelityItemList.find((x) => x.value.toLowerCase() === key)?.label : '';
		return label ?? key;
	};

	private initSelectList() {
		/* Get All Codes */
		var airlines = this.checkoutService.companyCodeList.map((x) => new KeyValuePair<string, KeyValuePair<string, string>>('airlines', x));
		var trains = this.checkoutService.trainCompanyCodeList.map((x) => new KeyValuePair<string, KeyValuePair<string, string>>('train', x));
		var hotels = this.checkoutService.hotelCompanyCodeList.map((x) => new KeyValuePair<string, KeyValuePair<string, string>>('hotel', x));

		this.fidelityItemList = new Array<SelectItem>();
		const fidelityCodeList = airlines.concat(trains).concat(hotels);
		fidelityCodeList.forEach((element) => {
			if (element.value.key) {
				this.fidelityItemList.push({ value: element.value.key, label: element.value.value, styleClass: element.key });
			}
		});
	}

	private newFilteredSelectItemsObs(index: number): Observable<SelectItem[]> {
		return this.form.at(index).controls.programId.valueChanges.pipe(
			startWith<string | SelectItem>(''),
			map((value) => this.filter(typeof value === 'string' ? value : value.label))
		);
	}

	private filter(value: string): SelectItem[] {
		const filterValue = value.toLowerCase().trim();
		return this.fidelityItemList.filter((option) => option.label.toLowerCase().includes(filterValue) || option.value.toLowerCase().includes(filterValue));
	}
}
