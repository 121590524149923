import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomizationsService } from '@app/shared/customizations/customizations.service';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private readonly route: Router, private readonly customizationsService: CustomizationsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.customizationsService.intercept(req, this.route.routerState.snapshot.url);
        return next.handle(req);
    }
}
