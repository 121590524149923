import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CheckoutService } from '@app/shared/services/flight/checkout.service';
import { UtilsModule } from '@shared/utils/utils.module';
import { FidelityCardDialogComponent } from './fidelity-card-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [FidelityCardDialogComponent],
	imports: [
		CommonModule,
		UtilsModule,
		MatDialogModule,
		ReactiveFormsModule,
		FormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatIconModule,
		MatInputModule,
		MatTooltipModule
	],
	providers: [CheckoutService],
})
export class FidelityCardDialogModule {}
