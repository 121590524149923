import { HttpRequest } from "@angular/common/http";
import { deleteCookies, getInfoFromCookie, sessionCookieName, sessionHeaderName, setCustomHeader, settingsCookieName, settingsHeaderName } from "@app/shared/helpers/cookie.utility";

export class SabreCustomizationsUtils {

    static showTripConfirmCustomButtons(): boolean {
        const cookieInfo = getInfoFromCookie();
        return !!cookieInfo[sessionCookieName];
    }

    static onBeforeUnload() {
        window.addEventListener('beforeunload', (_) => {
            deleteCookies([settingsCookieName, sessionCookieName]);
        });
    }

    static intercept(request: HttpRequest<any>, routeUrl: string): HttpRequest<any> {
        const cookieInfo = getInfoFromCookie();
        request = SabreCustomizationsUtils.manageLocalSettings(request, cookieInfo);
        request = SabreCustomizationsUtils.manageSession(request, cookieInfo);

        return request;
    }

    private static manageSession(req: HttpRequest<any>, cookieInfo: { [key: string]: string; }): HttpRequest<any> {
        const sessionInfo = cookieInfo[sessionCookieName];
        if (sessionInfo) {
            return setCustomHeader(req, sessionHeaderName, decodeURIComponent(sessionInfo));
        }

        return req;
    }

    private static manageLocalSettings(req: HttpRequest<any>, cookieInfo: { [key: string]: string; }): HttpRequest<any> {
        const settings = cookieInfo[settingsCookieName];
        if (settings) {
            return setCustomHeader(req, settingsHeaderName, decodeURIComponent(settings));
        }

        return req;
    }
}