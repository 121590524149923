import { Injectable } from '@angular/core';
import { PersonNameDataDto, UserProfileDto } from '@app/api/models';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PassengersService {
	private userProfileSubject: BehaviorSubject<UserProfileDto[]> = new BehaviorSubject<UserProfileDto[]>([]);

	constructor() {}

	setUsersProfile(key: string, array: UserProfileDto[]): void {
		this.setPassengers(key, array);
		this.userProfileSubject.next(array);
	}

	getUsersProfile(key: string): Observable<UserProfileDto[]> {
		return this.userProfileSubject.pipe(map((t) => JSON.parse(sessionStorage.getItem(`passengerList-${key}`)) as UserProfileDto[]));
	}

	setCurrentUserProfile(key: number, valu: UserProfileDto): void {
		sessionStorage.setItem(`userProfile-${key}`, JSON.stringify(valu));
	}

	getCurrentUserProfile(key: number): UserProfileDto {
		return JSON.parse(sessionStorage.getItem(`userProfile-${key}`)) as UserProfileDto;
	}

	setPassengers(key: string, passenger: PersonNameDataDto[] | UserProfileDto[]) {
		sessionStorage.setItem(`passengerList-${key}`, JSON.stringify(passenger));
	}

	getPassengers(availabilityId: string): PersonNameDataDto[] {
		const passengers = JSON.parse(sessionStorage.getItem(`passengerList-${availabilityId}`));
		return passengers;
	}

	getUsers(tripId: string): UserProfileDto[] {
		return JSON.parse(sessionStorage.getItem(`passengerList-${tripId}`));
	}
}
