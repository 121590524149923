import { TravelerType, TripItemTypeDto, UserProfileDto } from "@app/api/models";
import { TravelerTypeService } from "../services/traveler-type/traveler-type.service";
import { mergeMap, of } from "rxjs";

export function setTravelerTypeAsync(travelers: UserProfileDto[], tripType: TripItemTypeDto, travelerTypeService: TravelerTypeService) {
    return travelerTypeService.getGenericTravelers(tripType).pipe(
        mergeMap(travelerTypes => of(setTravelerType(travelers, tripType, travelerTypes))));
}

export function setTravelerType(travelers: UserProfileDto[], tripType: TripItemTypeDto, travelerTypes: TravelerType[]): UserProfileDto[] {
        /* Add ages where missing */
		const tmpTypes = normalizeTravelerTypes(tripType, travelerTypes);
		
		travelers.forEach((t) => {
			const age = getTravelerAge(t);
			const travelerType = tmpTypes.find((tt) => tt.ages.includes(age));
			const travelerTypeCode = travelerType?.code ?? 'ADT';
			t.travelerType = travelerTypeCode;
		});

        return travelers;
}

export function getTravelerAge(up: UserProfileDto): number {
    if (!up.birthDate) return -1;
    const today = new Date();
    const birthDate = new Date(up.birthDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    // Subtract a year if the birthday has not occurred yet this year
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function normalizeTravelerTypes(type: TripItemTypeDto, _travelerTypes: TravelerType[]) : TravelerType[] {
    // DeepClone della lista perchè vado a mosificare gli oggetti all'interno
    const travelerTypes = _travelerTypes.map((t) => ({ ...t }));
    switch (type) {
        case TripItemTypeDto.Flight:
        case TripItemTypeDto.PackageRate:
        case TripItemTypeDto.Hotel: {
            const chdAges = travelerTypes.find((t) => t.code === 'CHD')?.ages ?? [];
            travelerTypes.find((t) => t.code === 'ADT').ages = generateIntRange(chdAges[chdAges.length - 1] + 1, 100);
            break;
        }
        case TripItemTypeDto.Train: {
            const yngAges = travelerTypes.find((t) => t.code === 'YNG')?.ages ?? [];
            travelerTypes.find((t) => t.code === 'ADT').ages = generateIntRange(yngAges[yngAges.length - 1] + 1, 60);
            travelerTypes.find((t) => t.code === 'SEN').ages = generateIntRange(61, 100);
            break;
        }
        case TripItemTypeDto.Car: {
            travelerTypes.find((t) => t.code === 'ADT').ages = generateIntRange(0, 100);
            break;
        }
        default:
            //do nothing lol
            break;
    }

    return travelerTypes;
}

function generateIntRange(start: number, end: number): number[] {
    let range: number[] = [];
    for (let i = start; i <= end; i++) {
        range.push(i);
    }
    return range;
}