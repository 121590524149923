<mat-divider *ngIf="!isEdit"></mat-divider>

<div class="travelers-row">
    <ng-container *ngFor="let item of form.controls.travelers.valueChanges | async">
        <ng-container  [ngTemplateOutlet]="item.travelerType && item.isEditMode ? autoComplete : readonlyAutoComplete" [ngTemplateOutletContext]="{ item: item }"/>
    </ng-container>

    <ng-container *ngIf="!isEdit && addable"  [ngTemplateOutlet]="autoComplete"/>

    <ng-template #readonlyAutoComplete let-item="item">
        <div [ngClass]="{'age-field':  (item | acceptAgeRange)}" class="form-field-container">
            <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" >
                <mat-label>{{ 'Trip_Details_Traveler' | localize }}</mat-label>
                <input type="text" matInput [value]="item.givenName + ' ' + item.surname" readonly (click)="onUserProfileClick(item)" class="traveler-input" />
                <mat-icon matPrefix fontIcon="person" color="primary"></mat-icon>
                <div matSuffix class="d-flex flex-row">
                    <button mat-icon-button (click)="openCardDialog(item)" *ngIf="showCardButton">
                        <mat-icon fontIcon="add_card" [color]="item.fidelityCardPrograms?.length > 0 ? 'primary' : 'accent'"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="removeTraveler(item)" *ngIf="!isEdit && removableItems">
                        <mat-icon fontIcon="close" color="primary"></mat-icon>
                    </button>
                </div>
                
            </mat-form-field>

            <mat-form-field class="ageField" appearance="outline" floatLabel="always" subscriptSizing="dynamic" *ngIf="item | acceptAgeRange">
                <mat-label>{{ 'Common_Age' | localize }}</mat-label>
                <mat-select #matSelect placeholder="{{ 'Common_Age' | localize }}"  (selectionChange)="onAgeSelected($event, item)" required *ngIf="item?.ages?.length > 1">
                    <mat-option *ngFor="let age of item.ages" [value]="age"> {{age}} </mat-option>
                </mat-select>
                <ng-container *ngIf="!(item?.ages?.length > 1)">
                    <input [value]="item | userProfileAge" type="text" placeholder="{{ 'Common_Age' | localize }}" matInput [disabled]="true"/>
                </ng-container>
            </mat-form-field>   
        </div>
    </ng-template>

    <ng-template #autoComplete let-item="item">
        <ng-container [formGroup]="autocompleteForm">
            <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                <mat-label>{{ 'Trip_Details_Traveler' | localize }}</mat-label>
                <input id="autocompleteFormInput" type="text" placeholder="{{ 'Traveler_Add' | localize }}" matInput [matAutocomplete]="auto" formControlName="autocomplete" (focusout)="onUserProfileFocusOut(item)" />
                <mat-icon matPrefix [loadingIcon]="startLoading" fontIconLoading="person_add" color="primary"></mat-icon>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        <mat-icon fontIcon="person" color="primary"></mat-icon>
                        <b>{{ option.fullName }}</b>
                        <br />
                        {{ option.email }}
                    </mat-option>
                    <mat-option  (click)="addProfile(item)">
                        <mat-icon fontIcon="person_add" color="primary"></mat-icon>
                        {{ 'Traveler_Add' | localize }}
                    </mat-option>
					<ng-container *ngFor="let item of prebuiltTravelers">
						<mat-option (click)="addPrebuiltTraveler(item)">
							<mat-icon fontIcon="person_add" color="primary"></mat-icon>
							{{ (item.title)| localize }}
						</mat-option>
					</ng-container>
                </mat-autocomplete>
                <mat-error *ngIf="autocompleteForm.controls.autocomplete.hasError('travelers')">
                    {{ 'Common_min' | localize }}
                </mat-error>
            </mat-form-field>
        </ng-container>
    </ng-template>
</div>