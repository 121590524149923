import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FeatureCheckerService, PermissionCheckerService } from 'abp-ng2-module';

@Component({
	selector: 'app-services-search-container',
	templateUrl: './services-search-container.component.html',
	styleUrls: ['./services-search-container.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ServicesSearchContainerComponent implements OnInit {
	showPackageRate: boolean;
	showHotel: boolean;
	showCar: boolean;
	showFlight: boolean;
	showTrain: boolean;
	
	constructor(private feature: FeatureCheckerService, private permission: PermissionCheckerService){}

	ngOnInit(): void {
        this.showPackageRate = this.feature.isEnabled('App.Trip.PackageRate');
		this.showHotel = this.permission.isGranted('Pages.Hotel.Search');
		this.showCar = this.permission.isGranted('Pages.Car.Search');
		this.showFlight = this.permission.isGranted('Pages.Flight.Search');
		this.showTrain = this.permission.isGranted('Pages.Train.Search');
	}
}
