/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AirBookRequestDto } from '../models/air-book-request-dto';
import { AjaxResponse } from '../models/ajax-response';
import { AjaxResponseOfBookTripResponseDto } from '../models/ajax-response-of-book-trip-response-dto';
import { AjaxResponseOfCheckTripDataForServicesOutputDto } from '../models/ajax-response-of-check-trip-data-for-services-output-dto';
import { AjaxResponseOfFieldConfigDto } from '../models/ajax-response-of-field-config-dto';
import { AjaxResponseOfFileDto } from '../models/ajax-response-of-file-dto';
import { AjaxResponseOfListOfFieldConfigDto } from '../models/ajax-response-of-list-of-field-config-dto';
import { AjaxResponseOfListOfPersonNameDataDto } from '../models/ajax-response-of-list-of-person-name-data-dto';
import { AjaxResponseOfListOfTravelerType } from '../models/ajax-response-of-list-of-traveler-type';
import { AjaxResponseOfListOfUserProfileDto } from '../models/ajax-response-of-list-of-user-profile-dto';
import { AjaxResponseOfPagedResultDtoOfRecentTripDto } from '../models/ajax-response-of-paged-result-dto-of-recent-trip-dto';
import { AjaxResponseOfPagedResultDtoOfTripListDto } from '../models/ajax-response-of-paged-result-dto-of-trip-list-dto';
import { AjaxResponseOfPricingInformationDto } from '../models/ajax-response-of-pricing-information-dto';
import { AjaxResponseOfTripDto } from '../models/ajax-response-of-trip-dto';
import { AjaxResponseOfTripItemDto } from '../models/ajax-response-of-trip-item-dto';
import { ApprovalProcessFlowDto } from '../models/approval-process-flow-dto';
import { ApprovalRequestInputDto } from '../models/approval-request-input-dto';
import { AuthorizationResponseDto } from '../models/authorization-response-dto';
import { BookTripRequestDto } from '../models/book-trip-request-dto';
import { CarBookRequestBaseDto } from '../models/car-book-request-base-dto';
import { CheckTripDataOutputDto } from '../models/check-trip-data-output-dto';
import { CostDivisionElementDto } from '../models/cost-division-element-dto';
import { CreateTripInput } from '../models/create-trip-input';
import { CustomFieldDto } from '../models/custom-field-dto';
import { FieldConfigInputDto } from '../models/field-config-input-dto';
import { LabelDto } from '../models/label-dto';
import { LogDto } from '../models/log-dto';
import { PagedResultDtoOfTripListDto } from '../models/paged-result-dto-of-trip-list-dto';
import { SaveTripSearchInput } from '../models/save-trip-search-input';
import { SearchItemDto } from '../models/search-item-dto';
import { SetDiscountCodeInfoDto } from '../models/set-discount-code-info-dto';
import { TrainBookRequestBaseDto } from '../models/train-book-request-base-dto';
import { TrainBookResponseDto } from '../models/train-book-response-dto';
import { TripDto } from '../models/trip-dto';
import { TripFilterDto } from '../models/trip-filter-dto';
import { TripItemTypeDto } from '../models/trip-item-type-dto';
import { TripStatusDto } from '../models/trip-status-dto';
import { TripUpdateDto } from '../models/trip-update-dto';
import { UpdateTravelerInputDto } from '../models/update-traveler-input-dto';
import { UpdateTripItemPassengersTypeRequest } from '../models/update-trip-item-passengers-type-request';
import { UserProfileDto } from '../models/user-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class TripService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTripById
   */
  static readonly GetTripByIdPath = '/api/services/app/Trip/GetTripById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTripById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripById$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetTripByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTripById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripById(params?: {
    id?: string;
  }): Observable<TripDto> {

    return this.getTripById$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation getAllUsersTrips
   */
  static readonly GetAllUsersTripsPath = '/api/services/app/Trip/GetAllUsersTrips';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsersTrips()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersTrips$Response(params?: {
    TripStatus?: Array<number>;
    'TripDateRange.Start'?: string;
    'TripDateRange.End'?: string;
    'DepartureDateRange.Start'?: string;
    'DepartureDateRange.End'?: string;
    IsTripDateRangeEnabled?: boolean;
    IsDepartureDateRangeEnabled?: boolean;
    TripCode?: string;
    Surname?: string;
    Name?: string;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfPagedResultDtoOfTripListDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetAllUsersTripsPath, 'get');
    if (params) {
      rb.query('TripStatus', params.TripStatus, {});
      rb.query('TripDateRange.Start', params['TripDateRange.Start'], {});
      rb.query('TripDateRange.End', params['TripDateRange.End'], {});
      rb.query('DepartureDateRange.Start', params['DepartureDateRange.Start'], {});
      rb.query('DepartureDateRange.End', params['DepartureDateRange.End'], {});
      rb.query('IsTripDateRangeEnabled', params.IsTripDateRangeEnabled, {});
      rb.query('IsDepartureDateRangeEnabled', params.IsDepartureDateRangeEnabled, {});
      rb.query('TripCode', params.TripCode, {});
      rb.query('Surname', params.Surname, {});
      rb.query('Name', params.Name, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPagedResultDtoOfTripListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUsersTrips$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersTrips(params?: {
    TripStatus?: Array<number>;
    'TripDateRange.Start'?: string;
    'TripDateRange.End'?: string;
    'DepartureDateRange.Start'?: string;
    'DepartureDateRange.End'?: string;
    IsTripDateRangeEnabled?: boolean;
    IsDepartureDateRangeEnabled?: boolean;
    TripCode?: string;
    Surname?: string;
    Name?: string;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<AjaxResponseOfPagedResultDtoOfTripListDto> {

    return this.getAllUsersTrips$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPagedResultDtoOfTripListDto>) => r.body as AjaxResponseOfPagedResultDtoOfTripListDto)
    );
  }

  /**
   * Path part for operation getTravelersType
   */
  static readonly GetTravelersTypePath = '/api/services/app/Trip/GetTravelersType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTravelersType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelersType$Response(params: {
    type: TripItemTypeDto;
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfTravelerType>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetTravelersTypePath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfTravelerType>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTravelersType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelersType(params: {
    type: TripItemTypeDto;
  }): Observable<AjaxResponseOfListOfTravelerType> {

    return this.getTravelersType$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfTravelerType>) => r.body as AjaxResponseOfListOfTravelerType)
    );
  }

  /**
   * Path part for operation getRecentTrips
   */
  static readonly GetRecentTripsPath = '/api/services/app/Trip/GetRecentTrips';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentTrips()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentTrips$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfPagedResultDtoOfRecentTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetRecentTripsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPagedResultDtoOfRecentTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRecentTrips$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentTrips(params?: {
  }): Observable<AjaxResponseOfPagedResultDtoOfRecentTripDto> {

    return this.getRecentTrips$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPagedResultDtoOfRecentTripDto>) => r.body as AjaxResponseOfPagedResultDtoOfRecentTripDto)
    );
  }

  /**
   * Path part for operation getTripListToExcel
   */
  static readonly GetTripListToExcelPath = '/api/services/app/Trip/GetTripListToExcel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTripListToExcel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripListToExcel$Response(params?: {
    TripStatus?: Array<number>;
    'TripDateRange.Start'?: string;
    'TripDateRange.End'?: string;
    'DepartureDateRange.Start'?: string;
    'DepartureDateRange.End'?: string;
    IsTripDateRangeEnabled?: boolean;
    IsDepartureDateRangeEnabled?: boolean;
    TripCode?: string;
    Surname?: string;
    Name?: string;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetTripListToExcelPath, 'get');
    if (params) {
      rb.query('TripStatus', params.TripStatus, {});
      rb.query('TripDateRange.Start', params['TripDateRange.Start'], {});
      rb.query('TripDateRange.End', params['TripDateRange.End'], {});
      rb.query('DepartureDateRange.Start', params['DepartureDateRange.Start'], {});
      rb.query('DepartureDateRange.End', params['DepartureDateRange.End'], {});
      rb.query('IsTripDateRangeEnabled', params.IsTripDateRangeEnabled, {});
      rb.query('IsDepartureDateRangeEnabled', params.IsDepartureDateRangeEnabled, {});
      rb.query('TripCode', params.TripCode, {});
      rb.query('Surname', params.Surname, {});
      rb.query('Name', params.Name, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTripListToExcel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripListToExcel(params?: {
    TripStatus?: Array<number>;
    'TripDateRange.Start'?: string;
    'TripDateRange.End'?: string;
    'DepartureDateRange.Start'?: string;
    'DepartureDateRange.End'?: string;
    IsTripDateRangeEnabled?: boolean;
    IsDepartureDateRangeEnabled?: boolean;
    TripCode?: string;
    Surname?: string;
    Name?: string;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<AjaxResponseOfFileDto> {

    return this.getTripListToExcel$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFileDto>) => r.body as AjaxResponseOfFileDto)
    );
  }

  /**
   * Path part for operation getTripsForUser
   */
  static readonly GetTripsForUserPath = '/api/services/app/Trip/GetTripsForUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTripsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripsForUser$Response(params: {
    TripsFilter: TripFilterDto;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<PagedResultDtoOfTripListDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetTripsForUserPath, 'get');
    if (params) {
      rb.query('TripsFilter', params.TripsFilter, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfTripListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTripsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripsForUser(params: {
    TripsFilter: TripFilterDto;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<PagedResultDtoOfTripListDto> {

    return this.getTripsForUser$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfTripListDto>) => r.body as PagedResultDtoOfTripListDto)
    );
  }

  /**
   * Path part for operation discardTrip
   */
  static readonly DiscardTripPath = '/api/services/app/Trip/DiscardTrip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `discardTrip()` instead.
   *
   * This method doesn't expect any request body.
   */
  discardTrip$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.DiscardTripPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `discardTrip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  discardTrip(params?: {
    id?: string;
  }): Observable<void> {

    return this.discardTrip$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteTrip
   */
  static readonly DeleteTripPath = '/api/services/app/Trip/DeleteTrip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTrip()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTrip$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.DeleteTripPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTrip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTrip(params?: {
    id?: string;
  }): Observable<void> {

    return this.deleteTrip$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLabelAutocomplete
   */
  static readonly GetLabelAutocompletePath = '/api/services/app/Trip/GetLabelAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabelAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabelAutocomplete$Response(params?: {
    query?: string;
  }): Observable<StrictHttpResponse<Array<LabelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetLabelAutocompletePath, 'get');
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LabelDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabelAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabelAutocomplete(params?: {
    query?: string;
  }): Observable<Array<LabelDto>> {

    return this.getLabelAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LabelDto>>) => r.body as Array<LabelDto>)
    );
  }

  /**
   * Path part for operation updateTraveler
   */
  static readonly UpdateTravelerPath = '/api/services/app/Trip/UpdateTraveler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTraveler()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTraveler$Response(params?: {
    body?: UpdateTravelerInputDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.UpdateTravelerPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTraveler$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTraveler(params?: {
    body?: UpdateTravelerInputDto
  }): Observable<TripDto> {

    return this.updateTraveler$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation setTripName
   */
  static readonly SetTripNamePath = '/api/services/app/Trip/SetTripName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setTripName()` instead.
   *
   * This method doesn't expect any request body.
   */
  setTripName$Response(params?: {
    tripId?: string;
    tripName?: string;
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetTripNamePath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.query('tripName', params.tripName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setTripName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setTripName(params?: {
    tripId?: string;
    tripName?: string;
  }): Observable<TripDto> {

    return this.setTripName$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation checkTripData
   */
  static readonly CheckTripDataPath = '/api/services/app/Trip/CheckTripData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkTripData()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkTripData$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<CheckTripDataOutputDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.CheckTripDataPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckTripDataOutputDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkTripData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkTripData(params?: {
    tripId?: string;
  }): Observable<CheckTripDataOutputDto> {

    return this.checkTripData$Response(params).pipe(
      map((r: StrictHttpResponse<CheckTripDataOutputDto>) => r.body as CheckTripDataOutputDto)
    );
  }

  /**
   * Path part for operation updateTrip
   */
  static readonly UpdateTripPath = '/api/services/app/Trip/UpdateTrip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTrip()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTrip$Response(params?: {
    body?: TripUpdateDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.UpdateTripPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTrip$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTrip(params?: {
    body?: TripUpdateDto
  }): Observable<AjaxResponseOfTripDto> {

    return this.updateTrip$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation checkTripDataForServices
   */
  static readonly CheckTripDataForServicesPath = '/api/services/app/Trip/CheckTripDataForServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkTripDataForServices()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkTripDataForServices$Response(params?: {
    tripId?: string;
    body?: Array<TripItemTypeDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfCheckTripDataForServicesOutputDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.CheckTripDataForServicesPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfCheckTripDataForServicesOutputDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkTripDataForServices$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkTripDataForServices(params?: {
    tripId?: string;
    body?: Array<TripItemTypeDto>
  }): Observable<AjaxResponseOfCheckTripDataForServicesOutputDto> {

    return this.checkTripDataForServices$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfCheckTripDataForServicesOutputDto>) => r.body as AjaxResponseOfCheckTripDataForServicesOutputDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkTripDataForServices$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  checkTripDataForServices$Xml$Response(params?: {
    tripId?: string;
    body?: Array<TripItemTypeDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfCheckTripDataForServicesOutputDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.CheckTripDataForServicesPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfCheckTripDataForServicesOutputDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkTripDataForServices$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  checkTripDataForServices$Xml(params?: {
    tripId?: string;
    body?: Array<TripItemTypeDto>
  }): Observable<AjaxResponseOfCheckTripDataForServicesOutputDto> {

    return this.checkTripDataForServices$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfCheckTripDataForServicesOutputDto>) => r.body as AjaxResponseOfCheckTripDataForServicesOutputDto)
    );
  }

  /**
   * Path part for operation getLabels
   */
  static readonly GetLabelsPath = '/api/services/app/Trip/GetLabels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabels$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetLabelsPath, 'get');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabels(params?: {
    tripId?: string;
  }): Observable<Array<string>> {

    return this.getLabels$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation setLabels
   */
  static readonly SetLabelsPath = '/api/services/app/Trip/SetLabels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLabels()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setLabels$Response(params?: {
    tripId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetLabelsPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setLabels$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setLabels(params?: {
    tripId?: string;
    body?: Array<string>
  }): Observable<TripDto> {

    return this.setLabels$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLabels$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setLabels$Xml$Response(params?: {
    tripId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetLabelsPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setLabels$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setLabels$Xml(params?: {
    tripId?: string;
    body?: Array<string>
  }): Observable<TripDto> {

    return this.setLabels$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation crateAssistedTrip
   */
  static readonly CrateAssistedTripPath = '/api/services/app/Trip/CrateAssistedTrip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crateAssistedTrip()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  crateAssistedTrip$Response(params?: {
    body?: CreateTripInput
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.CrateAssistedTripPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `crateAssistedTrip$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  crateAssistedTrip(params?: {
    body?: CreateTripInput
  }): Observable<TripDto> {

    return this.crateAssistedTrip$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation initTrip
   */
  static readonly InitTripPath = '/api/services/app/Trip/InitTrip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initTrip()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  initTrip$Response(params?: {
    name?: string;
    hqId?: number;
    cdcId?: number;
    body?: Array<UserProfileDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.InitTripPath, 'post');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('hqId', params.hqId, {});
      rb.query('cdcId', params.cdcId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `initTrip$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  initTrip(params?: {
    name?: string;
    hqId?: number;
    cdcId?: number;
    body?: Array<UserProfileDto>
  }): Observable<AjaxResponseOfTripDto> {

    return this.initTrip$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation addFlightItem
   */
  static readonly AddFlightItemPath = '/api/services/app/Trip/AddFlightItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFlightItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFlightItem$Response(params?: {
    tripId?: string;
    body?: AirBookRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddFlightItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addFlightItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFlightItem(params?: {
    tripId?: string;
    body?: AirBookRequestDto
  }): Observable<AjaxResponseOfTripDto> {

    return this.addFlightItem$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation createPreviewFlightItem
   */
  static readonly CreatePreviewFlightItemPath = '/api/services/app/Trip/CreatePreviewFlightItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPreviewFlightItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPreviewFlightItem$Response(params?: {
    tripId?: string;
    body?: AirBookRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTripItemDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.CreatePreviewFlightItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripItemDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPreviewFlightItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPreviewFlightItem(params?: {
    tripId?: string;
    body?: AirBookRequestDto
  }): Observable<AjaxResponseOfTripItemDto> {

    return this.createPreviewFlightItem$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripItemDto>) => r.body as AjaxResponseOfTripItemDto)
    );
  }

  /**
   * Path part for operation addHotelItem
   */
  static readonly AddHotelItemPath = '/api/services/app/Trip/AddHotelItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addHotelItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  addHotelItem$Response(params?: {
    tripId?: string;
    availabilityDetailId?: string;
    optionId?: string;
    availabilityId?: string;
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddHotelItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.query('availabilityDetailId', params.availabilityDetailId, {});
      rb.query('optionId', params.optionId, {});
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addHotelItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addHotelItem(params?: {
    tripId?: string;
    availabilityDetailId?: string;
    optionId?: string;
    availabilityId?: string;
  }): Observable<TripDto> {

    return this.addHotelItem$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation addTrainItem
   */
  static readonly AddTrainItemPath = '/api/services/app/Trip/AddTrainItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTrainItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTrainItem$Response(params?: {
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddTrainItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTrainItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTrainItem(params?: {
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<TripDto> {

    return this.addTrainItem$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTrainItem$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addTrainItem$Xml$Response(params?: {
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddTrainItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTrainItem$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addTrainItem$Xml(params?: {
    tripId?: string;
    body?: TrainBookRequestBaseDto
  }): Observable<TripDto> {

    return this.addTrainItem$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation addCarItem
   */
  static readonly AddCarItemPath = '/api/services/app/Trip/AddCarItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCarItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCarItem$Response(params?: {
    tripId?: string;
    body?: CarBookRequestBaseDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddCarItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCarItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCarItem(params?: {
    tripId?: string;
    body?: CarBookRequestBaseDto
  }): Observable<TripDto> {

    return this.addCarItem$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCarItem$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addCarItem$Xml$Response(params?: {
    tripId?: string;
    body?: CarBookRequestBaseDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddCarItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCarItem$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addCarItem$Xml(params?: {
    tripId?: string;
    body?: CarBookRequestBaseDto
  }): Observable<TripDto> {

    return this.addCarItem$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation removeTripItem
   */
  static readonly RemoveTripItemPath = '/api/services/app/Trip/RemoveTripItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTripItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTripItem$Response(params?: {
    tripId?: string;
    tripItemId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.RemoveTripItemPath, 'delete');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.query('tripItemId', params.tripItemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeTripItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTripItem(params?: {
    tripId?: string;
    tripItemId?: string;
  }): Observable<AjaxResponseOfTripDto> {

    return this.removeTripItem$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation getItineraryData
   */
  static readonly GetItineraryDataPath = '/api/services/app/Trip/GetItineraryData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItineraryData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItineraryData$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetItineraryDataPath, 'get');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getItineraryData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItineraryData(params?: {
    tripId?: string;
  }): Observable<AjaxResponseOfTripDto> {

    return this.getItineraryData$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation saveTripSearch
   */
  static readonly SaveTripSearchPath = '/api/services/app/Trip/SaveTripSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTripSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveTripSearch$Response(params?: {
    body?: SaveTripSearchInput
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SaveTripSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveTripSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveTripSearch(params?: {
    body?: SaveTripSearchInput
  }): Observable<AjaxResponseOfTripDto> {

    return this.saveTripSearch$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTripSearch$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  saveTripSearch$Xml$Response(params?: {
    body?: SaveTripSearchInput
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SaveTripSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveTripSearch$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  saveTripSearch$Xml(params?: {
    body?: SaveTripSearchInput
  }): Observable<AjaxResponseOfTripDto> {

    return this.saveTripSearch$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation addTripSearchItem
   */
  static readonly AddTripSearchItemPath = '/api/services/app/Trip/AddTripSearchItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTripSearchItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTripSearchItem$Response(params?: {
    tripId?: string;
    body?: SearchItemDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddTripSearchItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTripSearchItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTripSearchItem(params?: {
    tripId?: string;
    body?: SearchItemDto
  }): Observable<AjaxResponse> {

    return this.addTripSearchItem$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTripSearchItem$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addTripSearchItem$Xml$Response(params?: {
    tripId?: string;
    body?: SearchItemDto
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddTripSearchItemPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTripSearchItem$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addTripSearchItem$Xml(params?: {
    tripId?: string;
    body?: SearchItemDto
  }): Observable<AjaxResponse> {

    return this.addTripSearchItem$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation saveTripSearchItems
   */
  static readonly SaveTripSearchItemsPath = '/api/services/app/Trip/SaveTripSearchItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTripSearchItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveTripSearchItems$Response(params?: {
    tripId?: string;
    body?: Array<SearchItemDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SaveTripSearchItemsPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveTripSearchItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveTripSearchItems(params?: {
    tripId?: string;
    body?: Array<SearchItemDto>
  }): Observable<AjaxResponseOfTripDto> {

    return this.saveTripSearchItems$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTripSearchItems$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  saveTripSearchItems$Xml$Response(params?: {
    tripId?: string;
    body?: Array<SearchItemDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SaveTripSearchItemsPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveTripSearchItems$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  saveTripSearchItems$Xml(params?: {
    tripId?: string;
    body?: Array<SearchItemDto>
  }): Observable<AjaxResponseOfTripDto> {

    return this.saveTripSearchItems$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation authorizeTrip
   */
  static readonly AuthorizeTripPath = '/api/services/app/Trip/AuthorizeTrip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizeTrip()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  authorizeTrip$Response(params?: {
    tripId?: string;
    body?: AuthorizationResponseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AuthorizeTripPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authorizeTrip$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  authorizeTrip(params?: {
    tripId?: string;
    body?: AuthorizationResponseDto
  }): Observable<AjaxResponseOfTripDto> {

    return this.authorizeTrip$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizeTrip$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  authorizeTrip$Xml$Response(params?: {
    tripId?: string;
    body?: AuthorizationResponseDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AuthorizeTripPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authorizeTrip$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  authorizeTrip$Xml(params?: {
    tripId?: string;
    body?: AuthorizationResponseDto
  }): Observable<AjaxResponseOfTripDto> {

    return this.authorizeTrip$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation switchTripToAgency
   */
  static readonly SwitchTripToAgencyPath = '/api/services/app/Trip/SwitchTripToAgency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `switchTripToAgency()` instead.
   *
   * This method doesn't expect any request body.
   */
  switchTripToAgency$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SwitchTripToAgencyPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `switchTripToAgency$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  switchTripToAgency(params?: {
    tripId?: string;
  }): Observable<AjaxResponseOfTripDto> {

    return this.switchTripToAgency$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation getCheckoutData_2
   */
  static readonly GetCheckoutData_2Path = '/api/services/app/Trip/GetCheckoutData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckoutData_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutData_2$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfBookTripResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetCheckoutData_2Path, 'get');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfBookTripResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheckoutData_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutData_2(params?: {
    tripId?: string;
  }): Observable<AjaxResponseOfBookTripResponseDto> {

    return this.getCheckoutData_2$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfBookTripResponseDto>) => r.body as AjaxResponseOfBookTripResponseDto)
    );
  }

  /**
   * Path part for operation approvalRequest
   */
  static readonly ApprovalRequestPath = '/api/services/app/Trip/ApprovalRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approvalRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approvalRequest$Response(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.ApprovalRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approvalRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approvalRequest(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<TripDto> {

    return this.approvalRequest$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approvalRequest$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  approvalRequest$Xml$Response(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.ApprovalRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approvalRequest$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  approvalRequest$Xml(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<TripDto> {

    return this.approvalRequest$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation startApprovalProcessFlow
   */
  static readonly StartApprovalProcessFlowPath = '/api/services/app/Trip/StartApprovalProcessFlow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startApprovalProcessFlow()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startApprovalProcessFlow$Response(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.StartApprovalProcessFlowPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startApprovalProcessFlow$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startApprovalProcessFlow(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<TripDto> {

    return this.startApprovalProcessFlow$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startApprovalProcessFlow$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startApprovalProcessFlow$Xml$Response(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.StartApprovalProcessFlowPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startApprovalProcessFlow$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startApprovalProcessFlow$Xml(params?: {
    body?: ApprovalRequestInputDto
  }): Observable<TripDto> {

    return this.startApprovalProcessFlow$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation approve
   */
  static readonly ApprovePath = '/api/services/app/Trip/Approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approve()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve$Response(params?: {
    tripId?: string;
    message?: string;
    reasonId?: string;
    approvalProcessId?: string;
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.ApprovePath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.query('message', params.message, {});
      rb.query('reasonId', params.reasonId, {});
      rb.query('approvalProcessId', params.approvalProcessId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve(params?: {
    tripId?: string;
    message?: string;
    reasonId?: string;
    approvalProcessId?: string;
  }): Observable<TripDto> {

    return this.approve$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation decline
   */
  static readonly DeclinePath = '/api/services/app/Trip/Decline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decline()` instead.
   *
   * This method doesn't expect any request body.
   */
  decline$Response(params?: {
    tripId?: string;
    message?: string;
    reasonId?: string;
    approvalProcessId?: string;
  }): Observable<StrictHttpResponse<TripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.DeclinePath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.query('message', params.message, {});
      rb.query('reasonId', params.reasonId, {});
      rb.query('approvalProcessId', params.approvalProcessId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `decline$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  decline(params?: {
    tripId?: string;
    message?: string;
    reasonId?: string;
    approvalProcessId?: string;
  }): Observable<TripDto> {

    return this.decline$Response(params).pipe(
      map((r: StrictHttpResponse<TripDto>) => r.body as TripDto)
    );
  }

  /**
   * Path part for operation hold
   */
  static readonly HoldPath = '/api/services/app/Trip/Hold';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hold()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hold$Response(params?: {
    body?: BookTripRequestDto
  }): Observable<StrictHttpResponse<TrainBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.HoldPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TrainBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `hold$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  hold(params?: {
    body?: BookTripRequestDto
  }): Observable<TrainBookResponseDto> {

    return this.hold$Response(params).pipe(
      map((r: StrictHttpResponse<TrainBookResponseDto>) => r.body as TrainBookResponseDto)
    );
  }

  /**
   * Path part for operation book_3
   */
  static readonly Book_3Path = '/api/services/app/Trip/Book';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `book_3()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book_3$Response(params?: {
    body?: BookTripRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.Book_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `book_3$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book_3(params?: {
    body?: BookTripRequestDto
  }): Observable<AjaxResponseOfTripDto> {

    return this.book_3$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation issue_4
   */
  static readonly Issue_4Path = '/api/services/app/Trip/Issue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issue_4()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue_4$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.Issue_4Path, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `issue_4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue_4(params?: {
    tripId?: string;
  }): Observable<void> {

    return this.issue_4$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation confirm_2
   */
  static readonly Confirm_2Path = '/api/services/app/Trip/Confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirm_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirm_2$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.Confirm_2Path, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirm_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirm_2(params?: {
    tripId?: string;
  }): Observable<AjaxResponseOfTripDto> {

    return this.confirm_2$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation addLog_1
   */
  static readonly AddLog_1Path = '/api/services/app/Trip/AddLog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLog_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  addLog_1$Response(params?: {
    tripId?: string;
    description?: string;
  }): Observable<StrictHttpResponse<Array<LogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.AddLog_1Path, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.query('description', params.description, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LogDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addLog_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addLog_1(params?: {
    tripId?: string;
    description?: string;
  }): Observable<Array<LogDto>> {

    return this.addLog_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LogDto>>) => r.body as Array<LogDto>)
    );
  }

  /**
   * Path part for operation changeStatus_1
   */
  static readonly ChangeStatus_1Path = '/api/services/app/Trip/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeStatus_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeStatus_1$Response(params?: {
    tripId?: string;
    body?: TripStatusDto
  }): Observable<StrictHttpResponse<Array<LogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.ChangeStatus_1Path, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LogDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeStatus_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeStatus_1(params?: {
    tripId?: string;
    body?: TripStatusDto
  }): Observable<Array<LogDto>> {

    return this.changeStatus_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LogDto>>) => r.body as Array<LogDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeStatus_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  changeStatus_1$Xml$Response(params?: {
    tripId?: string;
    body?: TripStatusDto
  }): Observable<StrictHttpResponse<Array<LogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.ChangeStatus_1Path, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LogDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeStatus_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  changeStatus_1$Xml(params?: {
    tripId?: string;
    body?: TripStatusDto
  }): Observable<Array<LogDto>> {

    return this.changeStatus_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LogDto>>) => r.body as Array<LogDto>)
    );
  }

  /**
   * Path part for operation getApprovalProcessFlow
   */
  static readonly GetApprovalProcessFlowPath = '/api/services/app/Trip/GetApprovalProcessFlow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalProcessFlow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalProcessFlow$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<ApprovalProcessFlowDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetApprovalProcessFlowPath, 'get');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApprovalProcessFlowDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApprovalProcessFlow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalProcessFlow(params?: {
    tripId?: string;
  }): Observable<ApprovalProcessFlowDto> {

    return this.getApprovalProcessFlow$Response(params).pipe(
      map((r: StrictHttpResponse<ApprovalProcessFlowDto>) => r.body as ApprovalProcessFlowDto)
    );
  }

  /**
   * Path part for operation setCostDivisionsCenters
   */
  static readonly SetCostDivisionsCentersPath = '/api/services/app/Trip/SetCostDivisionsCenters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCostDivisionsCenters()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setCostDivisionsCenters$Response(params?: {
    flowId?: string;
    processId?: string;
    body?: Array<CostDivisionElementDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetCostDivisionsCentersPath, 'post');
    if (params) {
      rb.query('flowId', params.flowId, {});
      rb.query('processId', params.processId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setCostDivisionsCenters$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setCostDivisionsCenters(params?: {
    flowId?: string;
    processId?: string;
    body?: Array<CostDivisionElementDto>
  }): Observable<void> {

    return this.setCostDivisionsCenters$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCostDivisionsCenters$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setCostDivisionsCenters$Xml$Response(params?: {
    flowId?: string;
    processId?: string;
    body?: Array<CostDivisionElementDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetCostDivisionsCentersPath, 'post');
    if (params) {
      rb.query('flowId', params.flowId, {});
      rb.query('processId', params.processId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setCostDivisionsCenters$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setCostDivisionsCenters$Xml(params?: {
    flowId?: string;
    processId?: string;
    body?: Array<CostDivisionElementDto>
  }): Observable<void> {

    return this.setCostDivisionsCenters$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFieldsConfig_1
   */
  static readonly GetFieldsConfig_1Path = '/api/services/app/Trip/GetFieldsConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFieldsConfig_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldsConfig_1$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfFieldConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetFieldsConfig_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfFieldConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFieldsConfig_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldsConfig_1(params?: {
  }): Observable<AjaxResponseOfListOfFieldConfigDto> {

    return this.getFieldsConfig_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfFieldConfigDto>) => r.body as AjaxResponseOfListOfFieldConfigDto)
    );
  }

  /**
   * Path part for operation createOrUpdateFieldConfig_1
   */
  static readonly CreateOrUpdateFieldConfig_1Path = '/api/services/app/Trip/CreateOrUpdateFieldConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateFieldConfig_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateFieldConfig_1$Response(params?: {
    body?: FieldConfigInputDto
  }): Observable<StrictHttpResponse<AjaxResponseOfFieldConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.CreateOrUpdateFieldConfig_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFieldConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateFieldConfig_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateFieldConfig_1(params?: {
    body?: FieldConfigInputDto
  }): Observable<AjaxResponseOfFieldConfigDto> {

    return this.createOrUpdateFieldConfig_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFieldConfigDto>) => r.body as AjaxResponseOfFieldConfigDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateFieldConfig_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateFieldConfig_1$Xml$Response(params?: {
    body?: FieldConfigInputDto
  }): Observable<StrictHttpResponse<AjaxResponseOfFieldConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.CreateOrUpdateFieldConfig_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfFieldConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateFieldConfig_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateFieldConfig_1$Xml(params?: {
    body?: FieldConfigInputDto
  }): Observable<AjaxResponseOfFieldConfigDto> {

    return this.createOrUpdateFieldConfig_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfFieldConfigDto>) => r.body as AjaxResponseOfFieldConfigDto)
    );
  }

  /**
   * Path part for operation setCustomFields
   */
  static readonly SetCustomFieldsPath = '/api/services/app/Trip/SetCustomFields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCustomFields()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setCustomFields$Response(params?: {
    tripId?: string;
    body?: Array<CustomFieldDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetCustomFieldsPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setCustomFields$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setCustomFields(params?: {
    tripId?: string;
    body?: Array<CustomFieldDto>
  }): Observable<AjaxResponseOfTripDto> {

    return this.setCustomFields$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCustomFields$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setCustomFields$Xml$Response(params?: {
    tripId?: string;
    body?: Array<CustomFieldDto>
  }): Observable<StrictHttpResponse<AjaxResponseOfTripDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetCustomFieldsPath, 'post');
    if (params) {
      rb.query('tripId', params.tripId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setCustomFields$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setCustomFields$Xml(params?: {
    tripId?: string;
    body?: Array<CustomFieldDto>
  }): Observable<AjaxResponseOfTripDto> {

    return this.setCustomFields$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripDto>) => r.body as AjaxResponseOfTripDto)
    );
  }

  /**
   * Path part for operation getTripTravelers
   */
  static readonly GetTripTravelersPath = '/api/services/app/Trip/GetTripTravelers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTripTravelers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripTravelers$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.GetTripTravelersPath, 'get');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTripTravelers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripTravelers(params?: {
    tripId?: string;
  }): Observable<AjaxResponseOfListOfUserProfileDto> {

    return this.getTripTravelers$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfUserProfileDto>) => r.body as AjaxResponseOfListOfUserProfileDto)
    );
  }

  /**
   * Path part for operation setDiscountCodeInfo
   */
  static readonly SetDiscountCodeInfoPath = '/api/services/app/Trip/SetDiscountCodeInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDiscountCodeInfo()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setDiscountCodeInfo$Response(params?: {
    body?: SetDiscountCodeInfoDto
  }): Observable<StrictHttpResponse<AjaxResponseOfPricingInformationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetDiscountCodeInfoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPricingInformationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setDiscountCodeInfo$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setDiscountCodeInfo(params?: {
    body?: SetDiscountCodeInfoDto
  }): Observable<AjaxResponseOfPricingInformationDto> {

    return this.setDiscountCodeInfo$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPricingInformationDto>) => r.body as AjaxResponseOfPricingInformationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDiscountCodeInfo$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setDiscountCodeInfo$Xml$Response(params?: {
    body?: SetDiscountCodeInfoDto
  }): Observable<StrictHttpResponse<AjaxResponseOfPricingInformationDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.SetDiscountCodeInfoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPricingInformationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setDiscountCodeInfo$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  setDiscountCodeInfo$Xml(params?: {
    body?: SetDiscountCodeInfoDto
  }): Observable<AjaxResponseOfPricingInformationDto> {

    return this.setDiscountCodeInfo$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPricingInformationDto>) => r.body as AjaxResponseOfPricingInformationDto)
    );
  }

  /**
   * Path part for operation updateTripItemPassengersType
   */
  static readonly UpdateTripItemPassengersTypePath = '/api/services/app/Trip/UpdateTripItemPassengersType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTripItemPassengersType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTripItemPassengersType$Response(params?: {
    body?: UpdateTripItemPassengersTypeRequest
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfPersonNameDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.UpdateTripItemPassengersTypePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfPersonNameDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTripItemPassengersType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTripItemPassengersType(params?: {
    body?: UpdateTripItemPassengersTypeRequest
  }): Observable<AjaxResponseOfListOfPersonNameDataDto> {

    return this.updateTripItemPassengersType$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfPersonNameDataDto>) => r.body as AjaxResponseOfListOfPersonNameDataDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTripItemPassengersType$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateTripItemPassengersType$Xml$Response(params?: {
    body?: UpdateTripItemPassengersTypeRequest
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfPersonNameDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, TripService.UpdateTripItemPassengersTypePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfPersonNameDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTripItemPassengersType$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateTripItemPassengersType$Xml(params?: {
    body?: UpdateTripItemPassengersTypeRequest
  }): Observable<AjaxResponseOfListOfPersonNameDataDto> {

    return this.updateTripItemPassengersType$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfPersonNameDataDto>) => r.body as AjaxResponseOfListOfPersonNameDataDto)
    );
  }

}
