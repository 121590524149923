import { HttpRequest } from "@angular/common/http";

/* Const */
export const settingsCookieName = 'settings';
export const settingsHeaderName = 'settings';
export const sessionCookieName = 'client-session';
export const sessionHeaderName = 'client-session';

/* Helpers */
export function getInfoFromCookie(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};
    const documentCookies = document.cookie;

    if (documentCookies) {
        const cookieArray = documentCookies.split(';');
        cookieArray.forEach(cookie => {
            const [name, value] = cookie.split('=').map(c => c.trim());
            cookies[name] = value;
        });
    }

    return cookies;
}

export function deleteCookies(names: string[] = null): void {
    const tmp = names ??
        document.cookie.split(';').map(cookie => cookie.split('=')[0].trim());

    tmp.forEach(name => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
}

export function setCustomHeader(req: HttpRequest<any>, headerName: string, headerData: string): HttpRequest<any> {
    return req.clone({
        setHeaders: {
            [headerName]: headerData
        }
    });
}