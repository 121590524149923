import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter, Renderer2, ViewChildren, QueryList, Injector } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileDto } from '@app/api/models';
import { UserProfilesService } from '@app/api/services';
import { UserProfileAutocomplete } from '@app/shared/models/form-interface/user-profile-autocomplete.models';
import { UserProfilesForm } from '@app/shared/models/form-interface/user-profiles-form.models';
import { startWith, filter, distinctUntilChanged, switchMap, Observable, map, finalize, of, debounce, timer, timeout, debounceTime, delay } from 'rxjs';
import { TravelerDialogComponent } from './traveler-dialog/traveler-dialog.component';
import { UserProfileDtoEmitterPair } from '@app/shared/models/emitter-interface/user-profile-dto-emitter-pair.interface';
import { UserProfileDtoExt } from '@app/shared/models/user-profile-dto-ext';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { UserProfilePrebuiltTravelerDto } from '@app/shared/models/user-profile-prebuilt-travelers-dto';
import * as moment from 'moment';
import { AppComponentBase } from '@shared/common/app-component-base';
import { acceptAgeRange } from '@shared/AppBusiness';
import { FidelityCardDialogComponent } from './fidelity-card-dialog/fidelity-card-dialog.component';
import { isVirtualUserProfile } from '@app/shared/helpers/user-profile-dto.utility';

export function travelerValidator(travelers: FormControl<UserProfileDto[]>): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (travelers && (!travelers.value || !travelers.value.length)) {
			return { travelers: true };
		}

		return null;
	};
}

@Component({
	selector: 'app-travelers-new',
	templateUrl: './travelers-new.component.html',
	styleUrls: ['./travelers-new.component.scss'],
})
export class TravelersNewComponent extends AppComponentBase implements OnInit, AfterViewInit {
	@Input() form: FormGroup<UserProfilesForm>;
	@Input() userProfiles: UserProfileDto[] | null;
	@Input() prebuiltTravelers: Array<UserProfileDto>;
	@Input() readOnly: Array<UserProfileDto>;
	@Input() showCardButton: boolean = true;
	@Input() enableCardButton: boolean = true;
	@Output() userProfilesOutput = new EventEmitter<UserProfileDtoEmitterPair>();

	filteredOptions: Observable<UserProfileDto[]>;
	startLoading: boolean;
	autocompleteForm: FormGroup<UserProfileAutocomplete>;
	isEdit: boolean;
	addable: boolean;
	removableItems: boolean;
	@ViewChildren('matSelect') matSelect: QueryList<MatSelect>;

	constructor(
		injector: Injector,
		private userProfileService: UserProfilesService,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private renderer: Renderer2
	) {
		super(injector);
	}

	ngOnInit(): void {
		this.addable = this.permission.isGranted('Pages.Trips.Create.For.All.Users');
		this.removableItems = this.permission.isGranted('Pages.Trips.Create.For.All.Users');

		this.isEdit = this.userProfiles && this.userProfiles.length > 0;

		this.autocompleteForm = this.formBuilder.group<UserProfileAutocomplete>({
			autocomplete: new FormControl(null, travelerValidator(this.form.controls.travelers)),
		});

		this.form.controls.travelers.valueChanges.pipe(debounceTime(100)).subscribe((_) => {
			this.matSelect.forEach((c) => {
				var color = !c.value ? 'var(--mdc-theme-error, #f44336)' : null;
				this.setMatselectLabelColor(c, color);
			});
		});

		this.filteredOptions = this.autocompleteForm.controls.autocomplete.valueChanges.pipe(
			startWith(''),
			filter((t) => (typeof t === 'string' && t.length >= 2) || typeof t === 'object'),
			debounce((val) => {
				if (typeof val === 'string') {
					return timer(400);
				}

				return timer(0);
			}),
			distinctUntilChanged(),
			switchMap((val) => {
				if (typeof val === 'string') {
					return this._filter(val || '');
				} else {
					this.addTraveler(val);
					return of([]);
				}
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.userProfiles && this.userProfiles.length) {
			this.form.controls.travelers.setValue(this.userProfiles);
		}
	}

	displayFn(traveler: UserProfileDto): string {
		return traveler && traveler.fullName ? traveler.fullName : '';
	}

	removeTraveler(traveler: UserProfileDto): void {
		this.form.controls.travelers.setValue(this.form.controls.travelers.getRawValue().filter((t) => t.id !== traveler.id));
	}

	addProfile(userProfile: UserProfileDtoExt | null): void {
		this.dialog
			.open(TravelerDialogComponent, {
				data: null,
			})
			.afterClosed()
			.subscribe((t) => {
				if (t) {
					if (userProfile) {
						userProfile.isEditMode = true;
					}

					this.addTraveler(t);
				}
			});
	}

	editProfile(traveler: UserProfileDto): void {
		if ((this.permission.isGranted('Pages.UserProfile.Update') || !this.addable)) {
			this.dialog
				.open(TravelerDialogComponent, {
					data: traveler,
				})
				.afterClosed()
				.pipe(filter((t) => !!t))
				.subscribe((edited: UserProfileDto) => {
					const tmp = this.form.controls.travelers.getRawValue() ?? [];
					const index = tmp.findIndex((t) => t.id === edited.id || Object.values(t.externalIds ?? {}).some((id) => Object.values(edited.externalIds ?? {}).some(_id => _id == id)));
					if (index !== -1) {
						const changes = <UserProfileDtoEmitterPair>{ addedUser: edited, removedUser: tmp, isEdit: true };
						tmp[index] = edited;
						this.form.controls.travelers.setValue(tmp);

						//Emit Value only if virtual
						if (isVirtualUserProfile(edited)) {
							edited.email ??= edited.userEmail; // Normalize email if not set
							this.userProfilesOutput.emit(changes);
						}
					}
				});
		}
	}

	addPrebuiltTraveler(traveler: UserProfileDtoExt) {
		this.addTraveler(traveler);
	}

	onUserProfileClick(item: UserProfileDtoExt) {
		if (item.isPlaceholder && this.isEdit) {
			item.isEditMode = true;
			setTimeout(() => {
				this.renderer.selectRootElement('#autocompleteFormInput').focus();
			}, 100);
		} else if (!item.travelerType || item.userId) {
			this.editProfile(item);
		}
	}

	onUserProfileFocusOut(item: UserProfileDtoExt) {
		setTimeout(() => {
			if (item != null) {
				item.isEditMode = false;
			}
		}, 100);
	}

	onAgeSelected(selectedAge: MatSelectChange, item: UserProfilePrebuiltTravelerDto) {
		const currentYear = new Date().getFullYear();
		const birthDate = new Date(currentYear - selectedAge.value, 0, 1);
		item.birthDate = moment(birthDate).format('L');
		/* Remove error color from label */
		this.setMatselectLabelColor(selectedAge.source, null);
	}

	openCardDialog(userProfile: UserProfileDtoExt) {
		if (this.permission.isGranted('Pages.UserProfile.Update')) {
			this.dialog
				.open(FidelityCardDialogComponent, {
					data: {
						userProfile: userProfile,
						readonly: !this.enableCardButton
					},
					width: '50%',
					height: '420px',
				})
				.afterClosed()
				.subscribe((cards: FormArray) => {
					if ((cards?.length >= 0) === true) {
						const tmp = this.form.controls.travelers.getRawValue() ?? [];
						const index = tmp.findIndex((t) => t.id === userProfile.id);

						if (index !== -1 && tmp[index]) {
							tmp[index]!.fidelityCardPrograms = cards.getRawValue(); //Non c'è bisogno che controllo se ci fossero già carte prima o meno
							this.form.controls.travelers.setValue(tmp);

							if (!tmp[index].travelerType) {
								this.showMainSpinner();
								this.userProfileService
									.createOrUpdateUserProfile({ body: { userProfile: tmp[index] } })
									.pipe(finalize(() => this.hideMainSpinner()))
									.subscribe();
							}
						}
					}
				});
		}
	}

	private addTraveler(val: UserProfileDtoExt) {
		if (val.isPlaceholder) {
			const copy = Object.assign({}, val);
			copy.id = crypto.randomUUID();
			val = copy;
		}

		let tmp = [...(this.form.controls.travelers.getRawValue() ?? [])];

		// Se c'è qualche item in edit
		if (this.isEdit && tmp.some((it) => it.isEditMode)) {
			const userProfileDeletedIndex = tmp.findIndex((it) => it.isEditMode);
			const removedItem = tmp.splice(userProfileDeletedIndex, 1, val)[0];

			if (acceptAgeRange(removedItem)) {
				//Controllo coerenza
				const newAge = val.birthDate ? new Date().getFullYear() - new Date(val.birthDate).getFullYear() : 0;
				const oldAge = removedItem.birthDate ? new Date().getFullYear() - new Date(removedItem.birthDate).getFullYear() : 0;
				/* Sarebbe da controllare il range di eta ma qua non ce l'ho, o chiamo le api o non credo ci sia un modo immediato */
				if (newAge && oldAge && newAge != oldAge) {
					/* ERRORE */
					removedItem.isEditMode = false;
					this.autocompleteForm.controls.autocomplete.setValue(null, { emitEvent: false });
					this.notify.error(this.l('Trip_Age_Not_Matching'));
					return;
				}
			}

			// Update Fidelity Card Programs
			if (!val.fidelityCardPrograms?.length) {
				val.fidelityCardPrograms = removedItem.fidelityCardPrograms;
			} else {
				const duplicatedCards = val.fidelityCardPrograms.filter((x) => removedItem.fidelityCardPrograms.some((z) => z.programId === x.programId));
				if (duplicatedCards?.length) {
					duplicatedCards.forEach((d) => {
						const newCard = removedItem.fidelityCardPrograms.find((oldCard) => oldCard.programId === d.programId);
						val.fidelityCardPrograms.splice(val.fidelityCardPrograms.indexOf(d), 1, newCard);
					});
				}

				val.fidelityCardPrograms.push(...removedItem.fidelityCardPrograms.filter((x) => !duplicatedCards.some((z) => z.programId === x.programId)));
			}

			//Per sicurezza
			tmp = tmp.filter((it) => !it.isEditMode);

			//Emit Value
			this.userProfilesOutput.emit({ addedUser: val, removedUser: removedItem });
		} else {
			tmp.push(val);
		}

		this.form.controls.travelers.setValue(tmp);
		this.autocompleteForm.controls.autocomplete.setValue(null, { emitEvent: false });
	}

	private _filter(val: string): Observable<UserProfileDto[]> {
		this.startLoading = true;

		const values = this.form.controls.travelers.getRawValue() ?? [];

		return this.userProfileService
			.getUserProfileAutocomplete({
				input: val,
			})
			.pipe(
				filter((r) => r.success),
				map((r) => r.result.filter((t) => !values.some((tx) => tx.id === t.id))),
				finalize(() => (this.startLoading = false))
			);
	}

	private setMatselectLabelColor(matSelect: MatSelect, color: string) {
		matSelect['_elementRef'].nativeElement.parentElement.parentElement.querySelector('label').style.color = color;
	}
}
