import { FeatureCheckerService } from "abp-ng2-module";
import { HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { CustomizationProvider, CustomizationsFeatureCode } from "./customizations.utils";
import { SabreCustomizationsUtils } from "./providers/sabre-customizations.utils";

@Injectable()
export class CustomizationsService {
    isCustomizationEnabled: boolean = !!this.featureCheckerService.getValue(CustomizationsFeatureCode);
    customizationCode: string = this.featureCheckerService.getValue(CustomizationsFeatureCode);

    constructor(private readonly featureCheckerService: FeatureCheckerService) { }

    initialize(): void {
        this.onBeforeUnload();
    }

    intercept(request: HttpRequest<any>, routeUrl: string): HttpRequest<any> {
        if (this.isCustomizationEnabled) {
            switch (this.customizationCode) {
                case CustomizationProvider.Sabre: {
                    request = SabreCustomizationsUtils.intercept(request, routeUrl);
                    break;
                }
                default:
                    break;
            }
        }

        return request;
    }

    onBeforeUnload() {
        if (this.isCustomizationEnabled) {
            switch (this.customizationCode) {
                case CustomizationProvider.Sabre: {
                    SabreCustomizationsUtils.onBeforeUnload();
                    break;
                }
                default:
                    break;
            }
        }
    }

    showTripConfirmCustomButtons(): boolean {
        if (this.isCustomizationEnabled) {
            switch (this.customizationCode) {
                case CustomizationProvider.Sabre: {
                    return SabreCustomizationsUtils.showTripConfirmCustomButtons();
                }
                default:
                    break;
            }
        }

        return false
    }
}