import { Component, OnInit, Injector, OnChanges, SimpleChanges, IterableDiffers, IterableDiffer, DoCheck } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@shared/utils/utils.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { debounceTime, finalize, map, Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { FieldConfigService } from '@app/api/services';
import { OptionValueDto, UserProfileDto } from '@app/api/models';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ErrorValidationMessagesComponent } from '../../error-validation-messages/error-validation-messages.component';
import { BaseAutocompleteComponent } from '../autocomplete-base.component';
@Component({
	selector: 'app-autocomplete-lazy',
	templateUrl: './autocomplete-lazy.component.html',
	styleUrls: ['./../autocomplete-base.component.scss', './autocomplete-lazy.component.scss'],
	standalone: true,
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		CommonModule,
		UtilsModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		LoadingIconModule,
		MatDividerModule,
		ErrorValidationMessagesComponent,
	],
})
export class AutocompleteLazyComponent extends BaseAutocompleteComponent implements OnInit, DoCheck {
	private options?: OptionValueDto[] | null = null;
	filteredOptions$: Observable<OptionValueDto[]>;

	private iterableDiffer: IterableDiffer<UserProfileDto> | null;

	constructor(injector: Injector, private service: FieldConfigService, private iterable: IterableDiffers) {
		super(injector);
	}

	ngDoCheck(): void {
		const classChanges = this.iterableDiffer?.diff(this.userProfiles);

		if (classChanges) {
			this.initOptions();
    	}
	}

	override ngOnInit() {
		super.ngOnInit();
		//this.initOptions(); this is made inside ngDoCheck

		if(this.userProfiles) {
			this.iterableDiffer = this.iterable.find(this.userProfiles).create();
		}

		this.filteredOptions$ = this.group.controls[this.field.name].valueChanges.pipe(
			debounceTime(500),
			map((value: unknown | null) => {
				if (!!this.field.parentId && !this.isParentSelected) {
					// not searching, parent not selected
					return this.options?.length > 0 ? this.options : this.noOptionsResponse();
				}

				if (!this.options) {
					// options empty
					return this.isSearching ? [] : this.noOptionsResponse();
				}

				if (this.isSearching) {
					// searching options
					return [];
				}

				const txt = typeof value === 'string' ? value : '';
				return this._filter(txt ?? '');
			})
		);
	}

	onFocusIn() {
		if (this.group.controls[this.field.name].value === null) {
			this.triggerValueChange();
		}
	}

	private _filter(value: string): OptionValueDto[] {
		const filterValue = value?.toLowerCase() ?? '';
		const retVal = this.options?.filter((option) => option.value?.toLowerCase().includes(filterValue)) ?? [];
		return retVal?.length > 0 ? retVal : this.noOptionsResponse();
	}

	private initOptions() {
		if (!this.readonly) {
			const parentVal = this.group.controls[this.field.parentId]?.value;
			if (!this.field.parentId || (parentVal && parentVal['key'])) {
				this.isSearching = true;
				this.service
					.getFieldConfigOptionsDtoAutocomplete({ input: '', fieldName: this.field.name, parent: parentVal?.key, userProfiles: this.userProfiles?.map((x) => x.userId) })
					.pipe(finalize(() => (this.isSearching = false)))
					.subscribe((data) => {
						this.options = data.result ?? [];

						// Reset value if already set
						if (this.group.controls[this.field.name].value) {
							this.group.controls[this.field.name].setValue(null);
						}

						if (this.options.length === 1) {
							this.group.controls[this.field.name].setValue(this.options[0]);
						}
					});
			}
		}
	}

	protected onParentSelected(): void {
		super.onParentSelected();
		this.initOptions();
	}

	protected onParentUnselected(): void {
		super.onParentUnselected();
		this.options = null;
	}
}
