import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CardProgramIdValidator(ammissibleValues: string[]): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value !== null && !ammissibleValues.includes(control.value)) {
			return { inputValidator: true };
		}
		return null;
	};
}
