<div class="d-flex flex-column h-100 w-100">
	<div class="d-flex flex-row justify-content-between align-items-center w-100 px-1">
		<div class="d-flex flex-row flex-gap align-items-center justify-content-start p-4">
            <h2 class="m-0">{{ 'UserProfileDto_FidelityCardPrograms_title' | localize }}</h2>
            <mat-icon fontIcon="info_i" color="accent" matTooltip="{{ 'UserProfileDto_FidelityCardPrograms_disclaimer' | localize }}"></mat-icon>
        </div>

		<button mat-button class="mr-2" color="primary" (click)="newCard()" *ngIf="!readonly">{{ 'UserProfileDto_FidelityCardPrograms_add' | localize }}</button>
	</div>
	<div mat-dialog-content class="d-flex flex-column w-100 flex-grow-1 px-10">
		<div class="d-flex h-100 w-100 justify-content-center align-items-center" *ngIf="!(form?.controls?.length > 0 === true); else cards">
			<p>{{ 'UserProfileDto_FidelityCardPrograms_NoData' | localize }}</p>
		</div>
	</div>
	<div mat-dialog-actions class="w-100" *ngIf="!readonly">
		<button mat-button (click)="closeDialog()">{{ 'Cancel' | localize }}</button>
		<button mat-button (click)="closeDialog(true)">{{ 'Save' | localize }}</button>
	</div>
</div>

<ng-template #cards>
	<div class="d-flex w-100 flex-column cards-gap flex-grow-1">
		<ng-container *ngFor="let cardForm of form.controls; let i = index">
			<ng-template [ngTemplateOutlet]="cardComponent" [ngTemplateOutletContext]="{ cardForm: cardForm, index: i }"></ng-template>
		</ng-container>
	</div>
</ng-template>

<ng-template #cardComponent let-form="cardForm" let-index="index">
	<div class="card d-flex flex-column" [formGroup]="form">
		<div class="mb-2">
			<h6 class="kt-portlet__head-text">{{ 'UserProfileDto_FidelityCardPrograms_title' | localize }} {{ index + 1 }}</h6>
		</div>

		<div class="d-flex flex-row flex-gap align-items-center">
			<mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
				<mat-label> {{ 'UserProfileDto_FidelityCardPrograms_title' | localize }} </mat-label>
				<mat-icon fontIcon="storefront" matPrefix color="primary"></mat-icon>
				<input
					matInput
					[matAutocomplete]="autocomplete"
					placeholder="{{ 'UserProfileDto_FidelityCardPrograms_title' | localize }}"
					spellcheck="false"
					autocomplete="off"
					autocorrect="off"
					autocapitalize="off"
					[formControl]="form.controls.programId"
				/>
				<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFunction">
					<mat-option *ngFor="let opt of filteredFidelityItemList[index] | async" [value]="opt.value">
						<img [src]="getLogoFidelityCard(opt.styleClass, opt.value)" class="frequentFlyer__Logo" />
						{{ opt.label }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
				<mat-label> {{ 'Flight_FrequentFlyer_FrequentFlyerMembershipId' | localize }} </mat-label>
				<mat-icon fontIcon="credit_card_heart" matPrefix color="primary"></mat-icon>
				<input matInput placeholder="{{ 'Flight_FrequentFlyer_FrequentFlyerMembershipId' | localize }}" [formControl]="form.controls.membershipId" />
			</mat-form-field>

			<button mat-icon-button color="accent" (click)="removeCard(index)" *ngIf="!readonly">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
</ng-template>
