/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponse } from '../models/ajax-response';
import { AjaxResponseOfListOfWebhookSubscriptionsDto } from '../models/ajax-response-of-list-of-webhook-subscriptions-dto';
import { AjaxResponseOfTask } from '../models/ajax-response-of-task';
import { WebhookSubscriptionsDto } from '../models/webhook-subscriptions-dto';

@Injectable({
  providedIn: 'root',
})
export class WebhookSubscriptionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation insertOrUpdate
   */
  static readonly InsertOrUpdatePath = '/api/services/app/WebhookSubscriptions/InsertOrUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertOrUpdate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertOrUpdate$Response(params?: {
    body?: WebhookSubscriptionsDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTask>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookSubscriptionsService.InsertOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTask>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insertOrUpdate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  insertOrUpdate(params?: {
    body?: WebhookSubscriptionsDto
  }): Observable<AjaxResponseOfTask> {

    return this.insertOrUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTask>) => r.body as AjaxResponseOfTask)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertOrUpdate$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  insertOrUpdate$Xml$Response(params?: {
    body?: WebhookSubscriptionsDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTask>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookSubscriptionsService.InsertOrUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTask>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `insertOrUpdate$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  insertOrUpdate$Xml(params?: {
    body?: WebhookSubscriptionsDto
  }): Observable<AjaxResponseOfTask> {

    return this.insertOrUpdate$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTask>) => r.body as AjaxResponseOfTask)
    );
  }

  /**
   * Path part for operation getAll_6
   */
  static readonly GetAll_6Path = '/api/services/app/WebhookSubscriptions/GetAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll_6()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll_6$Response(params?: {
    excludeDeleted?: boolean;
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfWebhookSubscriptionsDto>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookSubscriptionsService.GetAll_6Path, 'get');
    if (params) {
      rb.query('excludeDeleted', params.excludeDeleted, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfWebhookSubscriptionsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll_6$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll_6(params?: {
    excludeDeleted?: boolean;
  }): Observable<AjaxResponseOfListOfWebhookSubscriptionsDto> {

    return this.getAll_6$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfWebhookSubscriptionsDto>) => r.body as AjaxResponseOfListOfWebhookSubscriptionsDto)
    );
  }

  /**
   * Path part for operation delete_12
   */
  static readonly Delete_12Path = '/api/services/app/WebhookSubscriptions/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete_12()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete_12$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookSubscriptionsService.Delete_12Path, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete_12$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete_12(params?: {
    id?: number;
  }): Observable<AjaxResponse> {

    return this.delete_12$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation enableOrDisable
   */
  static readonly EnableOrDisablePath = '/api/services/app/WebhookSubscriptions/EnableOrDisable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableOrDisable()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableOrDisable$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookSubscriptionsService.EnableOrDisablePath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `enableOrDisable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableOrDisable(params?: {
    id?: number;
  }): Observable<AjaxResponse> {

    return this.enableOrDisable$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

}
