import { CommonModule } from "@angular/common";
import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TravelItemAdditionalService, TravelItemAdditionalServicePassenger, TravelItemAdditionalServiceType } from "@app/shared/models/travel-item-additional-service.model";
import { TripItemTypeDto } from "@shared/service-proxies/service-proxies";
import { UtilsModule } from "@shared/utils/utils.module";
import { SeatFormatModule } from "../../../pipes/seat-pipe/seat-format.module";
import { UtilitiesModule } from "@app/shared/pipes/utilities.module";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ExtraBagDto } from "@app/api/models";

@Component({
    selector: 'app-travel-item-additional-services-info',
    templateUrl: 'travel-item-additional-services-info.component.html',
    styleUrls: ['travel-item-additional-services-info.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, UtilsModule, SeatFormatModule, UtilitiesModule]
}) export class TravelItemAdditionalServiceInfoComponent extends AppComponentBase implements OnInit{
	tripItemTypeName: string;
    serviceTypeName: string;
    servicesByPassenger: TravelItemAdditionalServicePassenger[] = []
    singleOptionServices: TravelItemAdditionalService[] = []
    total: number = 0;
    isSingleOption: boolean;
	
    constructor(injector: Injector, public dialogRef: MatDialogRef<TravelItemAdditionalServiceInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super(injector);
    }

    ngOnInit(): void {        
        this.tripItemTypeName = TripItemTypeDto[this.data.tripItemType];
        this.serviceTypeName = TravelItemAdditionalServiceType[this.data.serviceType];
        this.initAdditionalServices();
    }

    initAdditionalServices(){
        for(var i = 0; i < this.data.passengers.length; i ++){
            var pax = new TravelItemAdditionalServicePassenger();
            pax.name = this.data.passengers[i].givenName;
            pax.surname = this.data.passengers[i].surname;
            pax.services = [];
            
            if(this.data.serviceType == TravelItemAdditionalServiceType.Seat && this.data.passengers[i].seats?.length > 0){
                this.data.passengers[i].seats.map(x => {
                    var service = new TravelItemAdditionalService();
                    service.ref = x.flightNumber;
                    service.name = x.seatName;
                    service.price = x.price;
                    pax.services.push(service);
                    this.total += x.price;
                })
            }

            if(this.data.serviceType == TravelItemAdditionalServiceType.ExtraOffers && this.data.passengers[i].extraOffers?.length > 0){
                this.data.passengers[i].extraOffers.map(x => {
                    var service = new TravelItemAdditionalService();
                    service.ref = x.id;
                    service.name = x.description;
                    service.price = x.price;
                    this.total += x.price;
                    
                    if(x.description.includes('rimborso') && this.data.passengers.length > 1){
                        this.singleOptionServices.push(service);
                    }
                    else{

                        pax.services.push(service);
                    }
                })
            }

            if(this.data.serviceType == TravelItemAdditionalServiceType.Baggage && this.data.passengers[i].extraBags?.length > 0){
                this.data.passengers[i].extraBags.map((x: ExtraBagDto) => {
                    var service = new TravelItemAdditionalService();
                    service.ref = x.segmentId;

                    if(x.isAirX)
                    {
                        const _str: string = x.quantity == 1 ? this.l("FlightBaggage_BagaglioPezzo") : this.l("FlightBaggage_BagaglioPezzi");
                        service.name = `${x.quantity} ${_str} ${x.weight} KG`;
                    }
                    else
                        service.name = `${x.ssrCode} (${x.rfic} - ${x.rfisc})`;

                    service.price = x.price.toString();
                    pax.services.push(service);
                    this.total += x.price;
                })
            }

            if(this.data.serviceType == TravelItemAdditionalServiceType.Sports && this.data.passengers[i].sportEquipments?.length > 0){
                this.data.passengers[i].sportEquipments.map(x => {
                    var service = new TravelItemAdditionalService();
                    service.ref = x.segmentId;
                    service.name = `${x.ssrCode} (${x.rfic} - ${x.rfisc})`;
                    service.price = x.price;
                    pax.services.push(service);
                    this.total += x.price;
                })
            }

            this.servicesByPassenger.push(pax);
        }
    }

    closeDialog(){
        this.dialogRef.close();
    }
}

